<template>
  <div class="mem-banner">
    <h3>数智人资·云效   i-HRDP</h3>
    <p>我们坚信“人”是所有业务的发展之本，<br/>
      建立以人为本的企业人力资源数字化体系，<br/>
      逐步形成以企业人才大脑为核心的画像库，<br/>
      为企业积累宝贵的人力资源要素数据，<br/>
      助推企业技术创新能力的提升。</p>
    <a :href="onLine" target="_blank">立即咨询</a>
  </div>
  <Target/>
  <UnifyMember/>
  <Advantage/>
  <MemVaule/>
</template>
<script>
import { onLine } from "@/data.ts";
import Target from '@/components/member/Target.vue';
import UnifyMember from '@/components/member/UnifyMember.vue';
import MemVaule from '@/components/member/Vaule.vue';
import Advantage from '@/components/member/Advantage.vue';
export default {
  components:{
    Target,
    UnifyMember,
    MemVaule,
    Advantage
  },
  data() {
    return { onLine }
  }
}
</script>
<style lang="less" scoped>
.mem-banner{
  padding: 65px 25px;
  background: url(/static/img/mem-banner.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
  >h3{
    font-size: 20px;
    font-weight: bold;
  }
  >p{
    font-size: 15px;
    font-weight: 300;
    line-height: 2;
    margin-top: 10px;
  }
  >a{
    line-height: 40px;
    text-align: center;
    display: inline-block;
    height: 40px;
    background: #F23D49;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 30px;
    border: 0 none;
    width: 115px;
  }
}
</style>